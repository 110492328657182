import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactTable from '../../common/UI/ReactTable';
import eyeIcon from '../../assets/images/eye.svg';
import { default as services } from '../../api/api';
import Loader from '../../common/UI/Loader';

const UserPastOrders = (props) => {
  const { userId } = props;

  const [orders, setOrders] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      Header: 'Order Number',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original.order_number}</span>;
      },
    },
    {
      Header: 'Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.createdAt).format('MM-DD-yyyy')}</span>
        );
      },
    },
    {
      Header: 'Order Total',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>$ {row.original.total}</span>;
      },
    },
    {
      Header: 'Items',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row.original?.orderItem.map((el, ind) => (
          <span key={el._id}>
            {ind + 1}
            {'. '}
            {el?.product?.name}
            <br />
          </span>
        ));
      },
    },

    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      width: 50,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              window.location.href = `/vieworder/${row.original._id}`;
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={eyeIcon} alt="view" width="24" height="24" />
          </span>
        );
      },
    },
  ];

  const getOrders = (pageNo) => {
    services
      .getUserPastOrders({ user_id: userId, perPage: 10, page: pageNo })
      .then((res) => {
        setOrders(res.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error: ', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getOrders(currentPageNo);
  }, [userId, currentPageNo]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  return (
    <div className="all_products_main">
      <div className="header_top ">
        <h4>Past Orders</h4>
      </div>
      <div className="middle_main">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <ReactTable
            title="User orders"
            tableColumns={columns}
            tableData={orders || []}
            seeAll={false}
            nextHandle={nextHandle}
            prevHandle={prevHandle}
            totalPage={totalPage}
            pageNo={currentPageNo}
          />
        )}
      </div>
    </div>
  );
};

export default UserPastOrders;
