import React, { useState, useEffect } from 'react';
import services from '../api/api';
import moment from 'moment';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const SendGP = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      Header: 'Order Number',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            className="text-primary text-decoration-underline cursor-pointer"
            onClick={() =>
              window.open(`/vieworder/${row?.original?._id}`, '_blank')
            }
          >{`${row.original?.order_number}`}</span>
        );
      },
    },
    {
      Header: 'Customer Name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span>{`${row.original?.user?.first_name} ${row.original?.user?.last_name}`}</span>
        );
      },
    },
    {
      Header: 'Date',
      accessor: (row) => moment(row?.createdAt).format('MM/DD/YYYY'),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Medications',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        let medications = row.original?.orderItem?.reduce((prev, curr) => {
          if (curr?.product?.type == 'medication')
            return curr?.product?.name
              ? prev + `${curr?.product?.name},`
              : prev;
        }, '');
        return <div>{medications}</div>;
      },
    },
    {
      Header: 'Med. Sub-category',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        let subCatSet = new Set();

        row.original?.orderItem?.length &&
          row.original?.orderItem?.forEach((el) => {
            if (el.product.type === 'medication') {
              subCatSet.add(el.product?.sub_category?.name);
            }
          });
        let str = '';
        subCatSet.forEach((el) => {
          if (!str) {
            str = el;
          } else {
            str = str + '\n' + el;
          }
        }, '');
        return <span>{`${str}`}</span>;
      },
    },
    {
      Header: 'GP Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <div>{row.original.gp_mail}</div>;
      },
    },
    {
      Header: 'Download Letters',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <a
              className="text-white text-decoration-none"
              href={row.original.gp_letter}
              download="GPLetter.pdf"
              target="blank"
            >
              <button className="btn btn-primary">Download</button>
            </a>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getOrdersWithGP(currentPageNo);
  }, [currentPageNo, seeAll]);

  const getOrdersWithGP = (pageNo, search = null) => {
    setLoading(true);
    let params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    params = { ...params, gpLetterSent: true };
    if (search) params = { ...params, search };
    services
      .getOrdersWithGPs(params)
      .then((res) => {
        setData(res.data.data.docs);
        setTotalPage(res.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  const onSearchDebounce = debounce(getOrdersWithGP, 1000);

  const handleOrderSearch = (value) => {
    onSearchDebounce(1, value);
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  // use this to download gp letters in bulk
  // const handleClick = () => {
  //   const filteredData = data.filter(
  //     (el) =>
  //       el.gp_mail === 'post@farmeci.com' || el.gp_mail === 'Post@farmeci.com',
  //   );
  //   filteredData.forEach((elem) => {
  //     const downloadLink = elem?.gp_letter;
  //     if (downloadLink) {
  //       axios({
  //         url: downloadLink,
  //         method: 'GET',
  //         responseType: 'blob', // Important: Set the response type to 'blob'
  //       })
  //         .then((response) => {
  //           const blob = new Blob([response.data], { type: 'application/pdf' });
  //           const url = window.URL.createObjectURL(blob);

  //           // Create a link element
  //           const link = document.createElement('a');
  //           link.href = url;
  //           link.download = `${elem.order_number}.pdf`; // Replace with your desired filename

  //           // Trigger a click event to start the download
  //           link.click();
  //           link.parentNode.removeChild(link);

  //           // Clean up the URL object
  //           window.URL.revokeObjectURL(url);
  //         })
  //         .catch((error) => {
  //           console.error('Error downloading PDF file:', error);
  //         });
  //     }
  //   });
  // };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Download GP Letters</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={handleOrderSearch}
                    searchPlaceholder={
                      'Search by order number, customer name, product name..'
                    }
                    seeAllHandle={seeAllHandle}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="GP Letters"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SendGP;
