import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import services from '../api/api';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ReactDatePicker from 'react-datepicker';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import AddressCard from '../components/AddressCard';
import UpdateAddressModal from '../components/Orders/ViewOrder/updateAddressModal';
import { Form, Formik } from 'formik';
import CustomInput from '../common/FormComponents/Input';
import { getErrorMessage } from '../utils/helpers';
import * as Yup from 'yup';
import ReactSelect from '../common/FormComponents/ReactSelect';
import Input from '../common/FormComponents/Input';
import { GENDER_OPTIONS } from '../common/constants';
import DatePickerField from '../common/FormComponents/DatePickerField';

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [updateThisAddress, setUpdateThisAddress] = useState({});
  const [userAddresses, setUserAddress] = useState([]);
  const [addressModalShow, setAddressModalShow] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    gender: '',
    phone: '',
  });

  useEffect(() => {
    if (id) {
      getUser();
      getUserAddresses();
    }
  }, []);

  useEffect(() => {
    setInitialValues({
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      dob: new Date(userData?.dob),
      gender: userData?.gender,
      phone: userData?.phone,
    });
  }, [userData]);

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .max(25, 'First name field should not exceed 25 characters')
      .required(getErrorMessage('required', 'First name')),
    last_name: Yup.string()
      .max(25, 'Last name field should not exceed 25 characters')
      .required(getErrorMessage('required', 'Last name')),
    email: Yup.string()
      .email(getErrorMessage('valid', 'Email'))
      .required(getErrorMessage('required', 'Email')),
    dob: Yup.date().nullable().required(getErrorMessage('required', 'DOB')),
  });

  const getUser = () => {
    services
      .getUserById(id)
      .then((res) => {
        console.log('res', res?.data?.data);
        setUserData(res?.data?.data);
        setInitialValues({
          first_name: res.data.data?.first_name,
          last_name: res.data.data?.last_name,
          email: res.data.data?.email,
          dob: new Date(res.data.data?.dob),
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getUserAddresses = () => {
    services
      .getAddressesByUserId(id)
      .then((res) => {
        setUserAddress(res?.data?.data?.docs || []);
      })
      .catch((err) => {
        console.log('err:', err);
      });
  };

  // const updateUserData = () => {
  //   const payload = {
  //     dob: dob,
  //   };
  //   services
  //     .updateUserById(userData?._id, payload)
  //     .then((res) => {
  //       console.log('res', res);
  //       toastSuccess('User Updated Successfully');
  //       setIsSubmitting(false);
  //       navigate('/users');
  //     })
  //     .catch((err) => {
  //       setIsSubmitting(false);
  //       console.log('err', err);
  //     });
  // };

  const editUserAddress = (values, onSubmitProps) => {
    services
      .updateUserAddress(values._id, {
        address_line1: values?.address_line1,
        address_line2: values?.address_line2,
        city: values?.city,
        state: values?.state?.value,
        state_code: values?.state?.state_code,
        zip_code: values?.zip_code,
        type: values?.type,
      })
      .then((res) => {
        toastSuccess('Updated Address Successfully');
        setUpdateThisAddress({});
        onSubmitProps.setSubmitting(false);
        setAddressModalShow(false);
        getUserAddresses();
      })
      .catch((err) => {
        console.log('err', err);
        onSubmitProps.setSubmitting(false);
      });
  };

  const onSubmit = (values, onSubmitProps) => {
    services
      .updateUserById(userData?._id, values)
      .then((res) => {
        toastSuccess('User Updated Successfully');
        onSubmitProps.setSubmitting(false);
        navigate('/users');
      })
      .catch((err) => {
        console.log('err', err);
        toastError(err?.message);
        onSubmitProps.setSubmitting(false);
      });
  };

  return (
    <>
      <section className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex col-md-12">
              <ol className="me-auto breadcrumb">
                <li>
                  <Link to={'/users'} title="User Management">
                    User Management
                  </Link>
                </li>
                <li>Edit User</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3
                    style={{
                      padding: '0.5rem',
                    }}
                  >
                    Edit User Data
                  </h3>
                </div>
                <div className="middle_main">
                  <div className="row">
                    <div className="col-md-6 mb-3 mb-md-0 col-lg-6 col-xl-4">
                      <table className="table condensed_table">
                        <tbody>
                          <tr>
                            <td width="40%">
                              <p className="customer_title_1">Name:</p>
                            </td>
                            <td width="60%">
                              <p className="customer_title_2">{`${userData?.first_name} ${userData?.last_name}`}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="customer_title_1">Email:</p>
                            </td>
                            <td>
                              <p className="customer_title_2">
                                {userData?.email}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className={'customer_title_1'}>Scorecard:</p>
                            </td>
                            <td>
                              <p className={'customer_title_2'}>
                                {userData?.verified?.scoreCard || ''}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td width="40%">
                              <p className="customer_title_1">Phone:</p>
                            </td>
                            <td width="60%">
                              <p className="customer_title_2">
                                {userData?.phone}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0 col-lg-6 col-xl-6">
                      <table className="table condensed_table">
                        <tbody>
                          <tr>
                            <td>
                              <p className={'customer_title_1'}>Smartscore:</p>
                            </td>
                            <td>
                              <p className={'customer_title_2'}>
                                {userData?.verified?.smartScore || ''}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className={'customer_title_1'}>Result Text:</p>
                            </td>
                            <td>
                              <p className={'customer_title_2'}>
                                {userData?.verified?.resultText || ''}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className={'customer_title_1'}>Email risk:</p>
                            </td>
                            <td>
                              <p className={'customer_title_2'}>
                                {userData?.verified?.emailRisk || ''}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className={'customer_title_1'}>Profile URL:</p>
                            </td>
                            <td>
                              <a
                                href={userData?.verified?.profileURL || '#'}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {userData?.verified?.profileURL}
                              </a>
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              <p className="customer_title_1">
                                Delivery Address:
                              </p>
                            </td>
                            <td>
                              <p className="customer_title_2">
                                {`${data?.address?.address_line1}`}
                                <br />
                                {data.address.address_line2
                                  ? `${data?.address?.address_line2}`
                                  : ''}
                                {`${data?.address?.city}, ${data?.address?.zip_code}, ${data?.address?.country?.value}`}
                                <br />
                                {`Type:- ${data?.address?.title}`}
                              </p>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    key={userData?._id}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <CustomInput
                                id={'first_name'}
                                label={'First Name'}
                                className={'form-control'}
                                type={'text'}
                                name={'first_name'}
                                placeholder={'First Name'}
                                isRequired={true}
                                onKeyPress={(e) =>
                                  !/[a-zA-z]/.test(e.key) && e.preventDefault()
                                }
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              <CustomInput
                                id={'last_name'}
                                label={'Last Name'}
                                className={'form-control'}
                                type={'text'}
                                name={'last_name'}
                                placeholder={'Last Name'}
                                isRequired={true}
                                onKeyPress={(e) =>
                                  !/[a-zA-z]/.test(e.key) && e.preventDefault()
                                }
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              <DatePickerField
                                dateFormat={'MM/dd/yyyy'}
                                placeholder="Select Dob"
                                className="form-control px-4"
                                name="dob"
                                label={'DOB'}
                                inputDateFormat="MM/dd/yyyy"
                                maxDate={new Date()}
                                onDaySelect={() => {}} //when day is clicked
                                onBlur={() => console.log('blur')}
                                // selected={dob}
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                // onChange={(val) => setDob(val)}
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              <CustomInput
                                id={'email'}
                                label={'Email'}
                                className={'form-control'}
                                type={'text'}
                                name={'email'}
                                placeholder={'Email'}
                                isRequired={true}
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              <ReactSelect
                                label="Select Gender"
                                labelClassname="form-label"
                                name="gender"
                                id="gender"
                                placeholder="Select Gender"
                                className="form-select"
                                options={GENDER_OPTIONS}
                                height={'40px'}
                                isRequired
                              />
                            </div>
                            <div className="col-xl-4 col-md-6 col-lg-6 form-group">
                              <Input
                                label="Phone"
                                labelClassname="form-label"
                                name="phone"
                                id="phone"
                                type="text"
                                className="form-control form-input"
                                placeholder="Phone"
                                isRequired
                              />
                            </div>
                            <div>
                              <div
                                className="header_top mt-2"
                                style={{
                                  borderTop: '1px solid #e5e5e5',
                                  borderBottom: 'none',
                                }}
                              >
                                <h3
                                  style={{
                                    padding: '0.5rem',
                                  }}
                                >
                                  Edit Addresses
                                </h3>
                              </div>
                              <div className="row">
                                {userAddresses.map((address) => {
                                  return (
                                    <div
                                      className="col-md-4 mb-4"
                                      key={address?._id}
                                      onClick={() => {
                                        setAddressModalShow((prev) => !prev);
                                        setUpdateThisAddress(address);
                                      }}
                                    >
                                      <AddressCard
                                        address={address}
                                        userId={userData._id}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="footer_main">
                            <Button
                              type="button"
                              variant="sky"
                              className="my-2 me-2"
                              onClick={() => navigate(`/users`)}
                            >
                              Back
                            </Button>
                            <Button
                              className="me-2"
                              type="submit"
                              disabled={formik.isSubmitting}
                            >
                              Edit
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {addressModalShow ? (
        <UpdateAddressModal
          address={updateThisAddress}
          show={addressModalShow}
          updateForUser={true}
          handleToggle={() => {
            setAddressModalShow(false);
            setUpdateThisAddress({});
          }}
          updateUserAddress={editUserAddress}
        />
      ) : null}
    </>
  );
};

export default EditUser;
