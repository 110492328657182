import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import services from '../api/api';
import { toastSuccess } from '../common/UI/Toasts';
import viewIcon from '../assets/images/eye.svg';
import ReactSwitch from 'react-switch';
import UserDetailsModal from '../components/UserDetailsModal';
import { useNavigate } from 'react-router-dom';
import editIcon from '../assets/images/pencil.svg';

const UserManagement = () => {
  const navigate = useNavigate();

  const getColor = (data) => {
    if (!data.verified?.address || !data.verified?.dob) {
      return 'black';
    }
    return 'black';
  };

  const columns = [
    {
      Header: 'Full Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: getColor(row.original),
            }}
          >
            {row.original.first_name} {row.original.last_name}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <a
            href={`mailto:${row.original.email}`}
            style={{
              color: getColor(row.original),
            }}
          >
            {row.original.email}
          </a>
        );
      },
    },
    {
      Header: 'Phone',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <a
            href={`tel:${row.original.phone}`}
            style={{
              color: getColor(row.original),
            }}
          >
            {row.original.phone}
          </a>
        );
      },
    },
    {
      Header: 'Role',
      accessor: 'role',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: getColor(row.original),
            }}
          >
            {row.original.role}
          </span>
        );
      },
    },
    {
      Header: 'Status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <ReactSwitch
            uncheckedIcon={false}
            checkedIcon={false}
            checked={row.original.status}
            //  text={row.original.status ? 'Active' : 'InActive'}
            onChange={() =>
              handleStatusChange(row.original._id, row.original.status)
            }
          />
        );
      },
    },
    {
      Header: 'Created Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: getColor(row.original),
            }}
          >
            {moment(row.original.createdAt).format('MM-DD-yyyy')}
          </span>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              style={{ cursor: 'pointer', color: getColor(row.original) }}
              onClick={() => {
                navigate(`/user-edit/${row.original._id}`);
              }}
            >
              <img src={editIcon} alt="pencil" width="24" height="24" />
            </span>
            <span
              style={{ cursor: 'pointer', color: getColor(row.original) }}
              onClick={() => {
                setUserInfo(row.original);
                setShowUserInfo(true);
              }}
            >
              <img src={viewIcon} alt="View" width="24" height="24" />
            </span>
          </div>
        );
      },
    },
  ];

  const CSVheaders = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email' },
    { label: 'Role', key: 'role' },
    { label: 'Phone', key: 'phone' },
    { label: 'Gender', key: 'gender' },
    { label: 'Addresses', key: 'address' },
    { label: 'Status', key: 'status' },
    { label: 'Created Date', key: 'createdAt' },
  ];

  const [csvData, setCsvData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUsers(currentPageNo);
  }, [currentPageNo, seeAll]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getUsers = (pageNo) => {
    const params = seeAll
      ? { showall: true, role: 'user' }
      : {
          perPage: 20,
          page: pageNo,
          role: 'user',
        };
    services
      .getUsers(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        loadCSVdata(res?.data?.data?.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const loadCSVdata = (userdata) => {
    const modifyuserdata =
      userdata &&
      userdata.length > 0 &&
      userdata.map((el) => {
        const modifyAddress =
          el.addressList &&
          el.addressList.length > 0 &&
          el.addressList.map((add, ind) => {
            return `${ind + 1}. ${add.title}, \n ${add.address_line1} \n ${
              add.address_line2
            } \n ${add.city}, ${add.zip_code}, ${add.country} - ${
              add.is_primary ? ' (Primary)' : ''
            }`;
          });
        const addArrToStr =
          modifyAddress && modifyAddress.length > 0 && modifyAddress.join('\n');
        return {
          first_name: el.first_name,
          last_name: el.last_name,
          email: el.email,
          role: el.role,
          phone: el.phone,
          gender: el.gender,
          address: addArrToStr || '',
          status: el.status,
          createdAt: moment(el.createdAt).format('MM-DD-yyyy'),
        };
      });
    setCsvData(modifyuserdata);
  };

  const getOnSearch = (value) => {
    const params = {
      search: value,
      role: 'user',
    };
    services
      .getUsersOnSearch(params)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const onSearchDebounce = debounce(getOnSearch, 1000);

  const handleUserSearch = (value) => {
    onSearchDebounce(value);
  };

  const handleToggleShowUserInfo = () => {
    setShowUserInfo(!showUserInfo);
  };

  const handleStatusChange = (id, status) => {
    const payload = { status: !status };
    services
      .updateUserStatus(id, payload)
      .then((res) => {
        if (res) {
          getUsers(currentPageNo);
          toastSuccess('Status Updated Successfully!!');
        }
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>User Management</h3>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ fontSize: '14px', color: 'red' }}>
                      {/* Red: Unverfied Account */}
                    </span>
                  </div>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={handleUserSearch}
                    // onButtonClick={handleExportUsers}
                    searchPlaceholder={'Search by user name...'}
                    // buttonName={
                    //   <CSVLink
                    //     data={csvData}
                    //     filename={`users_${moment().format(
                    //       'YYYY_MM_DD_HH_mm_ss',
                    //     )}.xlsx`}
                    //     target="_blank"
                    //     headers={CSVheaders}
                    //     style={{ color: '#fff' }}
                    //   >
                    //     Export Users
                    //   </CSVLink>
                    // }
                    seeAllHandle={seeAllHandle}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Users"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserDetailsModal
        show={showUserInfo}
        handleToggle={handleToggleShowUserInfo}
        userInfo={userInfo}
      />
    </>
  );
};

export default UserManagement;
