import React, { useState, useEffect } from 'react';
import services from '../api/api';
import moment from 'moment';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import GPEmailModal from '../components/Orders/ViewOrder/gpEmailModal';
import { CSVLink } from 'react-csv';
import { toastSuccess } from '../common/UI/Toasts';
import {
  gpAddressSubQuestionIDAndKeys,
  gpNameKey,
  staticIDs,
} from '../common/constants';
import { Spinner } from 'react-bootstrap';
import { debounce } from 'lodash';
import SearchAndButtons from '../common/UI/Search&Buttons';

const address_question_list = [
  'Main Question',
  'Address 1',
  'Address 2',
  'Address 3',
  'City',
  'Postcode',
];

const SendGP = () => {
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showGPEmailModal, setGPEmailModal] = useState(false);
  const [updateThisOrder, setUpdateThisOrder] = useState({});
  const [sendingLetterFor, setSendingLetterFor] = useState([]);

  const CSVheaders = [
    { label: 'Order Number', key: 'order_number' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'GP Name', key: 'OrganisationName' },
    { label: 'Address1', key: 'Address1' },
    { label: 'Address2', key: 'Address2' },
    { label: 'Address3', key: 'Address3' },
    { label: 'City', key: 'City' },
    { label: 'Post Code', key: 'Postcode' },
    { label: 'GP Mail', key: 'gp_mail' },
  ];

  useEffect(() => {
    getOrdersWithGP(currentPageNo);
  }, [currentPageNo, seeAll]);

  const columns = [
    {
      Header: 'Order Number',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            className="text-primary text-decoration-underline cursor-pointer"
            onClick={() =>
              window.open(`/vieworder/${row?.original?._id}`, '_blank')
            }
          >{`${row.original?.order_number}`}</span>
        );
      },
    },
    {
      Header: 'Customer Name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span>{`${row.original?.user?.first_name} ${row.original?.user?.last_name}`}</span>
        );
      },
    },
    {
      Header: 'Date',
      accessor: (row) => moment(row?.createdAt).format('MM/DD/YYYY'),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Medications',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        let medications = row.original?.orderItem?.reduce((prev, curr) => {
          if (curr?.product?.type == 'medication')
            return curr?.product?.name
              ? prev + `${curr?.product?.name},`
              : prev;
        }, '');
        return <div>{medications}</div>;
      },
    },
    {
      Header: 'Med. Sub-category',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        let subCatSet = new Set();

        row.original?.orderItem?.length &&
          row.original?.orderItem?.forEach((el) => {
            if (el.product.type === 'medication') {
              subCatSet.add(el.product?.sub_category?.name);
            }
          });
        let str = '';
        subCatSet.forEach((el) => {
          if (!str) {
            str = el;
          } else {
            str = str + '\n' + el;
          }
        }, '');
        return <span>{`${str}`}</span>;
      },
    },
    {
      Header: 'GP Email',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.gp_mail ? (
              row.original.gp_mail
            ) : (
              <span
                className="text-primary text-decoration-underline"
                role="button"
                tabIndex="1"
                onClick={() => {
                  window.open(`/vieworder/${row?.original?._id}`, '_blank');
                }}
              >
                Add GP Email
              </span>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Send Letters',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary"
              disabled={!row.original.gp_mail}
              onClick={() => {
                sendGPMail(row.original);
                setSendingLetterFor((prev) => [...prev, row.original._id]);
              }}
            >
              Send
              {sendingLetterFor.includes(row.original._id) ? (
                <span className="ms-2">
                  <Spinner animation="border" size="sm" />
                </span>
              ) : null}
            </button>
          </div>
        );
      },
    },
  ];

  const toggleGPEmailModal = (arg) => {
    // setting the email added by admin
    if (arg && arg.email) {
      const payload = {
        gp_mail: arg?.email,
      };
      services
        .updateGPMail(updateThisOrder?._id, payload)
        .then((res) => {
          setUpdateThisOrder({});
          getOrdersWithGP(currentPageNo);
          toastSuccess('Updated GP Mail Successfully');
        })
        .catch((err) => {
          setUpdateThisOrder({});
          console.log('err', err);
        });
    }
    setGPEmailModal((prev) => !prev);
  };

  const sendGPMail = (rowData) => {
    const orderItem = rowData?.orderItem?.find(
      (elem) => elem.product.type === 'medication',
    );

    const genAnswerObj = orderItem.general_ans.find(
      (el) => el?.question_id === staticIDs.gp_question,
    );

    let gpAddressObj = genAnswerObj?.question?.subQuestions?.find(
      (elem) => elem._id == gpNameKey,
    )?.answer;

    if (gpAddressObj?.__isNew__) {
      address_question_list.map((fld, index) => {
        const val = genAnswerObj?.question?.subQuestions?.find(
          (ans) => fld === gpAddressSubQuestionIDAndKeys[ans._id],
        );

        if (val) {
          const fldName =
            index == 0 ? 'OrganisationName' : fld.replace(' ', '');
          gpAddressObj = {
            ...gpAddressObj,
            [fldName]: index == 0 ? val.answer?.label : val.answer,
          };
        }
      });
    }
    let payload = {
      email: rowData.gp_mail,
      order_id: rowData._id,
      gp_address_id: !gpAddressObj.__isNew__ ? gpAddressObj?.value : null,
      gpObj: gpAddressObj.__isNew__ ? gpAddressObj : null,
    };
    services
      .sendGPLetter(payload)
      .then((res) => {
        console.log('res', res);
        getOrdersWithGP(currentPageNo);
        toastSuccess(`GP Letter Sent Successfully`);
        setSendingLetterFor((prev) => prev.filter((el) => el != rowData?._id));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getOrdersWithGP = (pageNo, search = null) => {
    setLoading(true);
    let params = seeAll
      ? { showall: true }
      : {
          perPage: 10,
          page: pageNo,
        };
    params = { ...params, gpLetterSent: false };
    if (search) params = { ...params, search };
    services
      .getOrdersWithGPs(params)
      .then((res) => {
        setData(res.data.data.docs);
        setTotalPage(res.data?.data?.totalPages);
        setLoading(false);
        loadCSVdata(res.data?.data?.docs);
      })
      .catch((err) => {
        console.log('err', err);
        setLoading(false);
      });
  };

  const onSearchDebounce = debounce(getOrdersWithGP, 1000);

  const handleOrderSearch = (value) => {
    onSearchDebounce(1, value);
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
          return {
            order_number: el?.order_number,
            customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
            OrganisationName: el?.gp_details?.OrganisationName,
            Address1: el?.gp_details?.Address1,
            Address2: el?.gp_details?.Address2,
            Address3: el?.gp_details?.Address3,
            City: el?.gp_details?.City,
            Postcode: el?.gp_details?.Postcode,
            gp_mail: el?.gp_mail,
          };
        })
      : [];
    setCsvData(modifyorderdata);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top d-flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Send GP Letters</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    onSearchChange={handleOrderSearch}
                    searchPlaceholder={
                      'Search by order number, customer name..'
                    }
                    seeAllHandle={seeAllHandle}
                    seeAll={seeAll}
                    buttonName={
                      <CSVLink
                        data={csvData}
                        filename={`orders_${moment().format(
                          'YYYY_MM_DD_HH_mm_ss',
                        )}.xlsx`}
                        target="_blank"
                        headers={CSVheaders}
                        style={{ color: '#fff' }}
                      >
                        Export Orders
                      </CSVLink>
                    }
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="GP Letters"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showGPEmailModal ? (
        <GPEmailModal show={showGPEmailModal} toggle={toggleGPEmailModal} />
      ) : null}
    </>
  );
};
export default SendGP;
