import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import CustomDropdown from '../../../common/UI/Dropdown';
import arrowDown from '../../../assets/images/arrow-down.png';
import { deliveryOptions } from '../../../common/constants';
import moment from 'moment';
import { isUserAdmin } from '../../../utils/helpers';
import services from '../../../api/api';
import { toastSuccess } from '../../../common/UI/Toasts';
import { getOrderById } from '../../../store/reducers/order';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomerDetails = (props) => {
  const { data } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const dispatch = useDispatch();
  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };
  const navigate = useNavigate();

  const showLexisNexisReport = () => {
    navigate(`lexisnexisreport/${data.user._id}`, {
      state: {
        ...location.state,
        viewOrderPath: location.pathname,
      },
    });
  };

  const validateUserButtonHandler = () => {
    services
      .validateUser(data.user._id)
      .then((res) => {
        toastSuccess(`User Validated Successfully`);
        dispatch(getOrderById(data?._id));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const validateOrderButtonHandler = () => {
    services
      .verifyOrder(data._id)
      .then((res) => {
        toastSuccess(`Phone/Email Validated Successfully`);
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>
      <div className="box_main" style={{ height: 'auto' }}>
        <div
          className="header_top "
          style={{ justifyContent: 'space-between' }}
        >
          <div className="d-flex justify-content-between">
            <h3>Customer Details</h3>

            {data?.user?.verification_status !== 'verified' && (
              <button
                type="button"
                className="btn btn-primary btn-sm order_btns ms-3"
                onClick={validateUserButtonHandler}
              >
                Validate This User
              </button>
            )}

            {!data?.is_order_verified && (
              <button
                type="button"
                className="btn btn-primary btn-sm order_btns ms-3"
                onClick={validateOrderButtonHandler}
              >
                Validate Phone/Email
              </button>
            )}
          </div>

          <div
            style={{ cursor: 'pointer', padding: '10px' }}
            onClick={() => {
              toggleExpanded();
            }}
          >
            {isExpanded ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </div>
        </div>
        {isExpanded && (
          <div className="middle_main">
            <div className="row">
              <div className="col-md-6 mb-3 mb-md-0 col-lg-6 col-xl-3">
                <table className="table condensed_table">
                  <tbody>
                    <tr>
                      <td width="40%">
                        <p className="customer_title_1">Name:</p>
                      </td>
                      <td width="60%">
                        <p className="customer_title_2">{`${data?.user?.first_name} ${data?.user?.last_name}`}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="customer_title_1">Email:</p>
                      </td>
                      <td>
                        <p className="customer_title_2">{data?.user?.email}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="customer_title_1">DOB:</p>
                      </td>
                      <td>
                        <p className="customer_title_2">
                          {data?.user?.dob
                            ? moment(data?.user?.dob).format('MM/DD/YYYY')
                            : '-'}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p className={'customer_title_1'}>
                          Phone/Email Validated:
                        </p>
                      </td>
                      <td>
                        {data?.is_order_verified
                          ? 'Confirmed'
                          : 'NOT CONFIRMED'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className={'customer_title_1'}>Stripe Validated:</p>
                      </td>
                      <td>
                        {data?.user?.verification_status == 'verified'
                          ? 'Yes'
                          : 'No'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 mb-3 mb-md-0 col-lg-6 col-xl-5">
                <table className="table condensed_table">
                  <tbody>
                    <tr>
                      <td width="40%">
                        <p className="customer_title_1">Gender:</p>
                      </td>
                      <td width="60%">
                        <p className="customer_title_2">{data?.user?.gender}</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">
                        <p className="customer_title_1">Phone:</p>
                      </td>
                      <td width="60%">
                        <p className="customer_title_2">{data?.user?.phone}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="customer_title_1">Delivery Service:</p>
                      </td>
                      <td>
                        <p className="customer_title_2">
                          {data?.delivery?.title}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="customer_title_1">Delivery Address:</p>
                      </td>
                      <td>
                        <p className="customer_title_2">
                          {`${data?.address?.address_line1}`}
                          <br />
                          {data.address.address_line2
                            ? `${data?.address?.address_line2}`
                            : ''}
                          {`${data?.address?.city}, ${data?.address?.zip_code}, ${data?.address?.state}`}
                          <br />
                          {`Type:- ${data?.address?.type}`}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {isUserAdmin() ? (
                <div className="col-md-6 mb-3 mb-md-0 col-lg-6 col-xl-4">
                  <table className="table mb-0 condensed_table">
                    <tbody>
                      <tr className="mb-0">
                        <td className="mb-0">
                          <p className="customer_title_1">Goods Total:</p>
                        </td>
                        <td className="mb-0">
                          <p className="customer_title_2">{`$ ${data?.goods_total?.toLocaleString(
                            'en',
                            { useGrouping: false, minimumFractionDigits: 2 },
                          )}`}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="customer_title_1">Delivery Total:</p>
                        </td>
                        <td>
                          <p className="customer_title_2">{`$ ${data?.delivery_total?.toLocaleString(
                            'en',
                            { useGrouping: false, minimumFractionDigits: 2 },
                          )}`}</p>
                        </td>
                      </tr>

                      {data?.coupon?.code ? (
                        <tr>
                          <td>
                            <p className="customer_title_1 text-danger">{`Coupon Discount: (${data?.coupon?.code})`}</p>
                          </td>
                          <td>
                            {' '}
                            <p className="customer_title_2">{`$ ${data?.total_coupon_discount?.toLocaleString(
                              'en',
                              { useGrouping: false, minimumFractionDigits: 2 },
                            )}`}</p>
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <p className="customer_title_1"> Total Amount:</p>
                        </td>
                        <td>
                          <p className="customer_title_2">{`$ ${data?.total?.toLocaleString(
                            'en',
                            { useGrouping: false, minimumFractionDigits: 2 },
                          )}`}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerDetails;
