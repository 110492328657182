import {
  faChevronCircleDown,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../api/api';
import ReactTable from '../../../common/UI/ReactTable';

const StatusLogs = (props) => {
  const pharmacistsAndDoctors = useSelector(
    (state) => state.order.pharmacistsAndDoctors,
  );
  const { data } = props;
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const columns = [
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <div>{`${row.index + 1}.)  ${row.original.action}`}</div>;
      },
    },
    {
      Header: 'Logs',
      accessor: 'status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.id) {
          const { id } = row.original;
          let user_name = '';
          const userObject = pharmacistsAndDoctors.find((el) => el._id === id);
          if (userObject) {
            user_name = `${userObject.first_name} ${userObject.last_name}`;
          } else {
            // call api to get name
            api.getUserById(id).then((res) => {
              const thisUser = res?.data?.data;
              user_name = `${thisUser.first_name} ${thisUser.last_name}`;
            });
          }
          return `${row.original.status} - ${user_name}`;
        } else {
          return row.original.status;
        }
      },
    },
    {
      Header: 'Done by',
      accessor: 'user_id',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.user_id) {
          const id = row.original.user_id;
          let user_name = '';
          const [name, setName] = useState('');
          const userObject = pharmacistsAndDoctors.find((el) => {
            return el._id === id;
          });
          if (userObject) {
            user_name = `${userObject.first_name} ${userObject.last_name} (${userObject.role})`;
          } else {
            // call api to get name
            api.getUserById(id).then((res) => {
              const thisUser = res?.data?.data;
              setName(
                `${thisUser.first_name} ${thisUser.last_name} (${thisUser.role})`,
              );
            });
          }
          return <span>{userObject ? user_name : name}</span>;
        }
        return <span>-</span>;
      },
    },
    {
      Header: 'Date-time',
      accessor: 'date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.date).format('MM/DD/YYYY, hh:mm a')}</span>
        );
      },
    },
  ];
  return (
    <div>
      <div className="header_top " style={{ justifyContent: 'space-between' }}>
        <h3>Status Logs</h3>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            toggleExpanded();
          }}
        >
          {isExpanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
      </div>
      {isExpanded ? (
        <ReactTable
          title="Status Logs"
          tableColumns={columns}
          tableData={props.data || []}
          seeAll={true}
        />
      ) : null}
    </div>
  );
};

export default StatusLogs;
