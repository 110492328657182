import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import { CSVLink } from 'react-csv';
import services from '../api/api';
import moment from 'moment';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import CustomDropdown from '../common/UI/Dropdown';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';

const Accounting = () => {
  const columns = [
    {
      Header: 'Order Number',
      disableSortBy: true,
      disableFilters: true,
      accessor: 'order_number',
    },
    {
      Header: 'Order Date',
      disableSortBy: true,
      disableFilters: true,
      accessor: (row) => `${moment(row?.createdAt).format('MM/DD/YYYY')}`,
    },
    {
      Header: 'Order Total(Ex. VAT)',
      disableSortBy: true,
      accessor: 'originalPriceTotal',
    },
    {
      Header: 'Order Total VAT',
      disableSortBy: true,
      accessor: 'VatRateTotal',
    },
    {
      Header: 'Delivery Charge',
      disableSortBy: true,
      accessor: 'delivery_total',
    },
    {
      Header: 'Delivery VAT',
      disableSortBy: true,
      accessor: 'deliveryVatRate',
    },
    {
      Header: 'Order Total',
      disableSortBy: true,
      accessor: 'order_total',
    },
    {
      Header: 'Total VAT',
      disableSortBy: true,
      disableFilters: true,
      accessor: 'sumOfDeliveryVatAndTotalVat',
    },
    {
      Header: 'Order Item Cost',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        let totalPrice = 0;
        row.original.orderItemArray.forEach((el) => {
          totalPrice = totalPrice + el?.calculatedTradePrice;
        });
        return <div>{totalPrice.toFixed(2)}</div>;
      },
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [dates, setDates] = useState({});

  const [selectedDates, setSelectedDates] = useState({
    start_date: '',
    end_date: '',
  });

  useEffect(() => {
    loadCSVdata(data);
  }, [data]);

  const CSVheaders = [
    { label: 'Order Number', key: 'order_number' },
    { label: 'Order Date', key: 'createdAt' },
    { label: 'Order Total(Ex. VAT)', key: 'order_total_exVAT' },
    { label: 'Order Total VAT', key: 'order_total_vat' },
    { label: 'Delivery Charges', key: 'delivery_total' },
    { label: 'Delivery VAT', key: 'delivery_vat' },
    { label: 'Order Total', key: 'order_total' },
    { label: 'Total VAT', key: 'total_vat' },
    { label: 'Order Item Cost', key: 'order_item_cost' },
  ];

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
          let order_item_cost = el?.orderItemArray?.reduce(
            (prev, curr) =>
              curr?.product?.name
                ? prev +
                  `${curr?.product?.name} - ${curr?.calculatedTradePrice},`
                : prev,
            '',
          );
          return {
            order_number: el?.order_number,
            createdAt: `${moment(el?.createdAt).format('MM/DD/YYYY')}`,
            order_total_exVAT: el?.originalPriceTotal,
            order_total_vat: el?.VatRateTotal,
            delivery_total: el?.delivery_total,
            delivery_vat: el?.deliveryVatRate,
            order_total: el?.order_total,
            total_vat: el?.sumOfDeliveryVatAndTotalVat,
            order_item_cost: order_item_cost,
          };
        })
      : [];
    setCsvData(modifyorderdata);
  };

  const handleFilterDataClick = () => {
    if (selectedDates.start_date !== '' && selectedDates.end_date !== '') {
      if (
        new Date(selectedDates.start_date) > new Date(selectedDates.end_date)
      ) {
        toastError(`Start date should be less than End date`);
      } else {
        let params = {
          sdt: selectedDates?.start_date,
          edt: selectedDates?.end_date,
        };
        setLoading(true);
        services
          .getOrderAccountingData(params)
          .then((res) => {
            setData(res.data.data);
            setLoading(false);
            setDates({
              start_date: selectedDates.start_date,
              end_date: selectedDates.end_date,
            });
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    } else {
      toastError(
        `Please Select ${
          selectedDates.start_date == '' ? 'Start' : 'End'
        } Date`,
      );
    }
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div
                  className="header_top"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h3>Accounting</h3>
                </div>

                <div className="middle_main">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Start Date</label>
                      <DatePicker
                        dateFormat={'MM/dd/yyyy'}
                        placeholderText="Select Start Date"
                        selected={selectedDates.start_date}
                        onChange={(val) => {
                          setSelectedDates((prev) => {
                            return { ...prev, start_date: val };
                          });
                        }}
                        className="form-control form-input px-4"
                        name="start_date"
                        inputDateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        onDaySelect={() => {}} //when day is clicked
                        onBlur={() => console.log('blur')}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">End Date</label>
                      <DatePicker
                        dateFormat={'MM/dd/yyyy'}
                        placeholderText="Select End Date"
                        selected={selectedDates.end_date}
                        onChange={(val) => {
                          setSelectedDates((prev) => {
                            return { ...prev, end_date: val };
                          });
                        }}
                        className="form-control form-input px-4"
                        name="start_date"
                        inputDateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        onDaySelect={() => {}} //when day is clicked
                        onBlur={() => console.log('blur')}
                      />
                    </div>
                    <div className="col-md-6 mt-4 text-end">
                      <Button
                        className="me-4 order_btns p-3"
                        onClick={handleFilterDataClick}
                      >
                        Filter Data
                      </Button>
                      <Button
                        className="me-4 order_btns p-3"
                        disabled={!data.length}
                      >
                        <CSVLink
                          data={csvData}
                          filename={`orders_${moment(dates.start_date).format(
                            'YYYY_MM_DD',
                          )} to ${moment(dates.end_date).format(
                            'YYYY_MM_DD',
                          )}.csv`}
                          target="_blank"
                          headers={CSVheaders}
                          style={{ color: '#fff' }}
                        >
                          Export Orders
                        </CSVLink>
                      </Button>
                    </div>
                  </div>
                  {Object.keys(dates).length ? (
                    <div className="mt-4">
                      {' '}
                      Order data from{' '}
                      <span className="fw-bold">
                        {moment(dates.start_date).format('MM/DD/YYYY')}
                      </span>{' '}
                      to{' '}
                      <span className="fw-bold">
                        {moment(dates.end_date).format('MM/DD/YYYY')}
                      </span>
                    </div>
                  ) : null}
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Subscription Orders"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounting;
