import React, { useState, useEffect } from 'react';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import CustomDropdown from '../common/UI/Dropdown';
import services from '../api/api';
import editIcon from '../assets/images/pencil.svg';
import eyeIcon from '../assets/images/eye.svg';
import deleteIcon from '../assets/images/trash.svg';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import ReactSelect from '../common/FormComponents/ReactSelect';
import { isUserAdmin, isUserDoctor, isUserPharmacist } from '../utils/helpers';
import { toastError, toastSuccess } from '../common/UI/Toasts';

const OrdersMeetingList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [orderCreateForm, setOrderCreateForm] = useState(false);
  const [userCreateForm, setUserCreateForm] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const [csvData, setCsvData] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [clinicianCall, setClinicianCall] = useState(null);
  const [clinicians, setClinicians] = useState(null);
  const [meetOrders, setMeetOrders] = useState(null);
  const [meetOrder, setMeetOrder] = useState(null);
  const [date, setDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState();
  const [editOrderId, setEditOrderId] = useState();
  const [editMeetingId, setEditMeetingId] = useState();
  const [editMeetingSlot, setEditMeetingSlot] = useState();
  const [editUserName, setEditUserName] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [usersData, setUsersData] = useState(false);
  const [userData, setUserData] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');

  const dateFormat = (date) => {
    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  const admin_columns = [
    {
      Header: 'Meeting Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {row.original?.start_date.substring(8, 10)}/
            {row.original?.start_date.substring(5, 7)}/
            {row.original?.start_date.substring(0, 4)}
          </span>
        );
      },
    },
    {
      Header: 'Meeting Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_date.substring(11, 16)}</span>;
      },
    },
    {
      Header: 'Meeting url',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              window.open(`${row.original?.meet_url}`, '_blank');
            }}
            style={{ cursor: 'pointer' }}
            className="join_meeting_btn"
          >
            Join Now
          </span>
        );
      },
    },
    {
      Header: 'User Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.user_name}</span>;
      },
    },
    {
      Header: 'Assigned Clinician',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.clinician_name) {
          return (
            <span>
              {row.original?.clinician_name}
              <CustomDropdown
                className=""
                options={
                  clinicians &&
                  clinicians
                    .filter((el) => el.role === 'doctor' && el.status)
                    .map((el) => {
                      return {
                        label: `Dr. ${el?.first_name} ${el?.last_name}`,
                        value: {
                          clinician_id: el?._id,
                          clinician_name: `${el?.first_name} ${el?.last_name}`,
                        },
                      };
                    })
                }
                height={'40px'}
                placeholder={'Select'}
                placeholderColor={'#9aa5ad'}
                border={'1 px solid #9aa5ad'}
                containerWidth={'175px'}
                onChange={(value) => {
                  assignClinician({
                    id: row.original?._id,
                    value,
                  });
                }}
                value={clinicianCall}
              />
            </span>
          );
        } else {
          return (
            <CustomDropdown
              className=""
              options={
                clinicians &&
                clinicians
                  .filter((el) => el.role === 'doctor' && el.status)
                  .map((el) => {
                    return {
                      label: `Dr. ${el?.first_name} ${el?.last_name}`,
                      value: {
                        clinician_id: el?._id,
                        clinician_name: `${el?.first_name} ${el?.last_name}`,
                      },
                    };
                  })
              }
              height={'40px'}
              placeholder={'Select'}
              placeholderColor={'#9aa5ad'}
              border={'1 px solid #9aa5ad'}
              containerWidth={'175px'}
              onChange={(value) => {
                assignClinician({
                  id: row.original?._id,
                  value,
                });
              }}
              value={clinicianCall}
            />
          );
        }
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <>
            <span onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
              <img src={editIcon} alt="view" width="24" height="24" />
            </span>
            {row?.original?.order_id && (
              <span
                onClick={() => {
                  window.open(`/vieworder/${row.original.order_id}`, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >
                <img src={eyeIcon} alt="view" width="24" height="24" />
              </span>
            )}
            <span
              onClick={() => assignMeetingStatus(row)}
              style={{ cursor: 'pointer' }}
            >
              <img src={deleteIcon} alt="view" width="24" height="24" />
            </span>
          </>
        );
      },
    },
  ];

  const clinician_columns = [
    {
      Header: 'Meeting Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>
            {row.original?.start_date.substring(8, 10)}/
            {row.original?.start_date.substring(5, 7)}/
            {row.original?.start_date.substring(0, 4)}
          </span>
        );
      },
    },
    {
      Header: 'Meeting Time',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.start_date.substring(11, 16)}</span>;
      },
    },
    {
      Header: 'Meeting url',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              window.open(`${row.original?.meet_url}`, '_blank');
            }}
            style={{ cursor: 'pointer' }}
            className="join_meeting_btn"
          >
            Join Now
          </span>
        );
      },
    },
    {
      Header: 'User Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{row.original?.user_name}</span>;
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <>
            <span onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
              <img src={editIcon} alt="view" width="24" height="24" />
            </span>
            <span
              onClick={() => {
                window.open(`/vieworder/${row.original.order_id}`, '_blank');
              }}
              style={{ cursor: 'pointer' }}
            >
              <img src={eyeIcon} alt="view" width="24" height="24" />
            </span>
            <span
              onClick={() => assignMeetingStatus(row)}
              style={{ cursor: 'pointer' }}
            >
              <img src={deleteIcon} alt="view" width="24" height="24" />
            </span>
          </>
        );
      },
    },
  ];

  const assignMeetingStatus = (row) => {
    api
      .assignClinicianMeet({ status: 'Cancelled' }, row.original?._id)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleEdit = (row) => {
    setEditMeetingId(row.original?._id);
    setEditMeetingSlot(row?.original?.order_id);
    setEditOrderId(row?.original?.order_id);
    setEditUserName(row?.original?.user_name);
    setOrderCreateForm(true);
  };

  useEffect(() => {
    setLoading(true);
    services
      .getAllMeetings()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });

    if (isUserAdmin()) {
      getCliniciansData();
      getOrdersData();
    }

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    services
      .getNextAvailableMeetingSlots([year, month, day].join('-'))
      .then((res) => {
        let next_date = new Date(res.data.data[0]);
        setDate(next_date);
        setAvailableSlots(res.data.data);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  }, []);

  const getCliniciansData = () => {
    api
      .getUsers({ showall: true, role: 'pharmacist,doctor,admin' })
      .then((res) => {
        setClinicians(res?.data?.data?.docs);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getOrdersData = () => {
    api
      .getMeetOrders()
      .then((res) => {
        setMeetOrders(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getUsersData = () => {
    api
      .getUsers({ showall: true, role: 'user' })
      .then((res) => {
        setUsersData(res.data.data.docs);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const assignClinician = (clinicianData) => {
    api
      .assignClinicianMeet(
        {
          clinician_id: clinicianData.value.value.clinician_id,
          clinician_name: clinicianData.value.value.clinician_name,
        },
        clinicianData.id,
      )
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const selectDate = (date) => {
    setDate(date);
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    services
      .getMeetingSlots([year, month, day].join('-'))
      .then((res) => {
        setAvailableSlots(res.data.data);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const scheduleCallApi = () => {
    setButtonClicked(true);
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    let payload = {};
    if (meetOrder) {
      payload = {
        order_id: meetOrder.value,
        meetDateWithTime: selectedSlot,
        user_id: meetOrder.user_id,
      };
    } else if (userEmail && userName) {
      payload = {
        meetDateWithTime: selectedSlot,
        user_email: userEmail,
        user_name: userName,
      };
    } else {
      setButtonClicked(false);
      toastError('Something went wrong!');
    }
    services
      .scheduleMeet(payload)
      .then((res) => {
        if (res.data.message == 'Meet Added') {
          navigate(0);
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const updateCallApi = () => {
    setButtonClicked(true);
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    const payload = {
      start_date: selectedSlot,
    };
    api
      .assignClinicianMeet(payload, editMeetingId)
      .then((res) => {
        if (res.data.message == '') {
          navigate(0);
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="row">
          <div className="col-md-12">
            {' '}
            <div className="container-fluid">
              {orderCreateForm && (
                <>
                  <ol className="breadcrumb">
                    <li>
                      <Link
                        to={'/orders-meeting-list'}
                        title="Meetings List"
                        onClick={() => setOrderCreateForm(false)}
                      >
                        Meetings List
                      </Link>
                    </li>
                    {editMeetingId ? (
                      <li>Edit a Meeting</li>
                    ) : (
                      <li>Create a Meeting</li>
                    )}
                    {name === 'viewBanner' && <li>View Details</li>}
                  </ol>
                  <div className="box_main">
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      {editMeetingId ? (
                        <h3>Edit a Meeting</h3>
                      ) : (
                        <h3>Create a Meeting</h3>
                      )}
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                      {editMeetingId ? (
                        <>User Name: {editUserName}</>
                      ) : (
                        <>
                          <h6 className="available-text">Select Order</h6>
                          <CustomDropdown
                            className=""
                            options={
                              meetOrders &&
                              meetOrders.map((el) => {
                                return {
                                  label: `${el.order_number}`,
                                  value: el._id,
                                  username: `${el.username}`,
                                  user_id: el.user_id,
                                };
                              })
                            }
                            height={'40px'}
                            placeholder={'Select'}
                            placeholderColor={'#9aa5ad'}
                            border={'1 px solid #9aa5ad'}
                            containerWidth={'175px'}
                            onChange={(value) => {
                              setMeetOrder(value);
                            }}
                            value={meetOrder}
                          />
                        </>
                      )}
                      {meetOrder ? (
                        <div className="col-xl-12 col-md-6 col-lg-6 form-group mt-3">
                          <p>User Name: {meetOrder.username}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <h6 className="available-text">Select Date</h6>
                    <ReactDatePicker
                      onChange={(value) => {
                        selectDate(value);
                      }}
                      selected={date}
                      style={{ zIndex: 99 }}
                      className="form-control form-input px-4 date-custom width-100"
                      dateFormat="MM/dd/yyyy"
                    />
                    <h6 className="available-text">
                      Available slots on {dateFormat(date)}
                    </h6>
                    <div className="slot-section">
                      {availableSlots.map((slot, ind) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setSelectedSlot(slot);
                              }}
                              className={`slot-box ${
                                selectedSlot == slot ? 'selectedSlot' : ''
                              }`}
                              key={`${ind}`}
                            >
                              <p>{slot.substring(11, 16)}</p>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div>
                      <Button
                        className={` ${
                          buttonClicked ? 'submitDisabled' : ''
                        } slot-submit`}
                        onClick={() => {
                          {
                            editMeetingId ? updateCallApi() : scheduleCallApi();
                          }
                        }}
                      >
                        {buttonClicked ? 'Loading...' : 'Submit'}
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {userCreateForm && (
                <>
                  <ol className="breadcrumb">
                    <li>
                      <Link
                        to={'/orders-meeting-list'}
                        title="Meetings List"
                        onClick={() => setOrderCreateForm(false)}
                      >
                        Meetings List
                      </Link>
                    </li>
                    {editMeetingId ? (
                      <li>Edit a Meeting</li>
                    ) : (
                      <li>Create a Meeting</li>
                    )}
                    {name === 'viewBanner' && <li>View Details</li>}
                  </ol>
                  <div className="box_main">
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      {editMeetingId ? (
                        <h3>Edit a Meeting</h3>
                      ) : (
                        <h3>Create a Meeting</h3>
                      )}
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                      {editMeetingId ? (
                        <>User Name: {editUserName}</>
                      ) : (
                        <>
                          <h6 className="available-text">Enter User Email</h6>
                          <input
                            value={userEmail || ''}
                            onChange={(e) => {
                              setUserEmail(e.target.value);
                            }}
                            className="form-control me-2"
                            type=""
                            placeholder="Enter user email"
                            aria-label=""
                          />
                          <h6 className="available-text">Enter User Name</h6>
                          <input
                            value={userName || ''}
                            onChange={(e) => {
                              setUserName(e.target.value);
                            }}
                            className="form-control me-2"
                            type=""
                            placeholder="Enter user name"
                            aria-label=""
                          />
                        </>
                      )}
                    </div>

                    <h6 className="available-text">Select Date</h6>
                    <ReactDatePicker
                      onChange={(value) => {
                        selectDate(value);
                      }}
                      selected={date}
                      style={{ zIndex: 99 }}
                      className="form-control form-input px-4 date-custom width-100"
                      dateFormat="MM/dd/yyyy"
                    />
                    <h6 className="available-text">
                      Available slots on {dateFormat(date)}
                    </h6>
                    <div className="slot-section">
                      {availableSlots.map((slot, ind) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setSelectedSlot(slot);
                              }}
                              className={`slot-box ${
                                selectedSlot == slot ? 'selectedSlot' : ''
                              }`}
                              key={`${ind}`}
                            >
                              <p>{slot.substring(11, 16)}</p>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div>
                      <Button
                        className={` ${
                          buttonClicked ? 'submitDisabled' : ''
                        } slot-submit`}
                        onClick={() => {
                          {
                            editMeetingId ? updateCallApi() : scheduleCallApi();
                          }
                        }}
                      >
                        {buttonClicked ? 'Loading...' : 'Submit'}
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {!orderCreateForm && !userCreateForm && (
                <div className="box_main">
                  <div
                    className="header_top"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3> Meetings List</h3>
                    {isUserAdmin() && (
                      <div>
                        <Button onClick={() => setUserCreateForm(true)}>
                          Create user meeting
                        </Button>
                        <Button onClick={() => setOrderCreateForm(true)}>
                          Create order meeting
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="middle_main">
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Subscription Orders"
                        tableColumns={
                          isUserAdmin() ? admin_columns : clinician_columns
                        }
                        tableData={data}
                        seeAll={seeAll}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                        isBottomPadding={true}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersMeetingList;
