import React, { useEffect, useState } from 'react';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import services from '../api/api';
import DeleteConfirmationModal from '../common/UI/CustomModal/deleteModal';
import { toastSuccess } from '../common/UI/Toasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import CustomModal from './../common/UI/CustomModal';
import profileImg from '../assets/images/profile-image.jpg';
import moment from 'moment';

const UserManagement = () => {
  const convertAddressLine = (address) => {
    if (!address) return '';
    const { address_line1, address_line2, city, country, zip_code } = address;

    return `${address_line1} ${
      address_line2 ? `${address_line2},` : ''
    }  ${city} ${country} ${zip_code}`;
  };

  const onNameClick = (data) => {
    setUserInfo(data);
    handleToggleShowUserInfo();
  };

  const columns = [
    {
      Header: 'User1: Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        const name = `${row.original.user1.first_name} ${row.original.user1.last_name}`;
        return (
          <a
            href="#"
            onClick={() =>
              onNameClick({
                ...row.original,
                ...row.original.user1,
                address: row.original.user1_address,
                name,
              })
            }
          >
            {name}
          </a>
        );
      },
    },
    {
      Header: 'User1: Email',
      minWidth: 120,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span className="text-wrap">{row.original.user1.email}</span>;
      },
    },
    {
      Header: 'User1: Address',
      minWidth: 150,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{convertAddressLine(row.original?.user1_address)}</span>;
      },
    },
    {
      Header: 'User2: Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        const name = `${row.original.user2.first_name} ${row.original.user2.last_name}`;
        return (
          <a
            href="#"
            onClick={() =>
              onNameClick({
                ...row.original,
                ...row.original.user2,
                address: row.original.user2_address,
                name,
              })
            }
          >
            {name}
          </a>
        );
      },
    },
    {
      Header: 'User2: Email',
      minWidth: 120,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span className="text-wrap">{row.original.user2.email}</span>;
      },
    },
    {
      Header: 'User2: Address',
      minWidth: 150,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return <span>{convertAddressLine(row.original.user2_address)}</span>;
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        const { user1, user2, _id } = row.original;
        return (
          <div className="d-flex align-items-center">
            <span className="me-2 mx-3">
              <FontAwesomeIcon
                icon={faUserCheck}
                size={'1x'}
                color={'#088fff'}
                className="m-2"
                style={{ cursor: 'pointer' }}
                onClick={() => handleApproveUser(_id)}
              />
            </span>
            <Button
              onClick={() =>
                handleDelete(_id, user1._id, user1.first_name, user1.last_name)
              }
              variant="danger"
              className="mx-1 btn_delete"
            >
              Delete User1
            </Button>
            <Button
              onClick={() =>
                handleDelete(_id, user2._id, user2.first_name, user2.last_name)
              }
              variant="danger"
              className="mx-1 btn_delete"
            >
              Delete User2
            </Button>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteUserName, setDeleteUserName] = useState('');
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [approveUserId, setApproveUserId] = useState('');
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    setLoading(true);
    getUsers(currentPageNo);
  }, [currentPageNo, seeAll]);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const handleDelete = (id, userId, firstName, lastName) => {
    setDeleteUserName(`${firstName} ${lastName}`);
    setDeleteUserId(userId);
    setApproveUserId(id);
    setModalShow(true);
  };

  const deleteUser = () => {
    const approveUser = services.approveDuplicateUser(approveUserId);
    const updateStatus = services.updateUserStatus(deleteUserId, {
      status: false,
    });
    Promise.all([approveUser, updateStatus])
      .then(() => {
        getUsers(currentPageNo);
        setDeleteUserId(null);
        toastSuccess('User Deleted Successfully');
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleApproveUser = (id) => {
    services
      .approveDuplicateUser(id)
      .then(() => {
        getUsers(currentPageNo);
        toastSuccess('User Approved Successfully');
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const getUsers = (pageNo) => {
    const params = seeAll
      ? { showall: true, role: 'user' }
      : {
          perPage: 10,
          page: pageNo,
        };
    services
      .getDuplicateUsers(params)
      .then((res) => {
        setData(res?.data?.data?.docs);
        setTotalPage(res?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const deleteModalOnHide = () => {
    setModalShow(false);
    setDeleteUserId(null);
  };

  const deleteModalOnDelete = () => {
    setModalShow(false);
    deleteUser();
  };

  const handleToggleShowUserInfo = () => {
    setShowUserInfo(!showUserInfo);
  };

  return (
    <>
      <CustomModal
        show={showUserInfo}
        title={`${userInfo?.name} Details`}
        handleToggle={handleToggleShowUserInfo}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3" style={{ textAlign: 'center' }}>
              <img
                src={userInfo?.photo?.location || profileImg}
                style={{
                  borderRadius: '50%',
                  width: '90px',
                  height: '90px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="col-md-9">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <p className="des_2">Full Name: </p>
                    </td>
                    <td>
                      <p className="des_3">{`${userInfo?.name}`}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Email: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        <a href={`mailto:${userInfo?.email}`}>
                          {userInfo?.email}
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Phone: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        <a href={`tel:${userInfo?.phone}`}>{userInfo?.phone}</a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Gender: </p>
                    </td>
                    <td>
                      <p className="des_3">{userInfo?.gender}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Date of Birth: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        {moment(userInfo?.dob).format('MM-DD-yyyy')}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Status: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        {userInfo?.status ? 'Active' : 'Inactive'}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Created Date: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        {moment(userInfo?.createdAt).format('MM-DD-yyyy')}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="des_2">Addresses: </p>
                    </td>
                    <td>
                      <p className="des_3">
                        {convertAddressLine(userInfo?.address) || ''}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CustomModal>
      <div className="content_wrapper all_products_main duplicate_users_listing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Duplicate User Management</h3>
                </div>
                <div className="middle_main">
                  <SearchAndButtons
                    seeAllHandle={seeAllHandle}
                    showSearchBox={false}
                  />
                  {loading ? (
                    <Loader loading={loading} />
                  ) : (
                    <ReactTable
                      title="Users"
                      tableColumns={columns}
                      tableData={data}
                      seeAll={seeAll}
                      nextHandle={nextHandle}
                      prevHandle={prevHandle}
                      totalPage={totalPage}
                      pageNo={currentPageNo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        name={deleteUserName}
        module={'user'}
        show={modalShow}
        handleToggle={deleteModalOnHide}
        handleDelete={deleteModalOnDelete}
      />
    </>
  );
};

export default UserManagement;
