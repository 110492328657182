import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { default as api, default as services } from '../../api/api';
import Loader from '../../common/UI/Loader';
import SearchAndButtons from '../../common/UI/Search&Buttons';
import ReactTable from '../../common/UI/ReactTable';

const defaultFilters = {
  admin: true,
  has_landingpage: true,
  type: 'medication',
  perPage: 20,
};

const GoogeAdsCatsTable = () => {
  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  const columns = [
    {
      Header: 'Sub-Category',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row?.original?.slug;
      },
    },
    {
      Header: 'Title',
      accessor: 'landingpage_title',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row?.original?.shoparize_title;
      },
    },
    {
      Header: 'Landing Page URL',
      accessor: '_id',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <a
            href={`${process.env.REACT_APP_LANDINGPAGE_BASE_URL}category/${row?.original?._id}`}
            target="blank"
          >
            {process.env.REACT_APP_LANDINGPAGE_BASE_URL}category/
            {row?.original?._id}
          </a>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return row.original.has_landingpage ? 'Active' : 'Inactive';
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getCats();
  }, []);

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  // Functions for api calls
  const getCats = () => {
    services
      .getSubCatLp()
      .then((response) => {
        console.log(response.data.data, 'reesss');
        let resData = response.data.data.filter(
          (data) => data.has_landingpage == true,
        );
        setData(resData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoading(false);
      });
  };

  const getSubCats = (pageNo) => {
    let params = seeAll
      ? {
          seeAll,
          ...defaultFilters,
        }
      : {
          ...defaultFilters,
          page: pageNo,
        };
    if (searchVal) params.name = searchVal;
    setLoading(true);

    services
      .getProducts(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoading(false);
      });
  };

  const getFilteredProductData = (value) => {
    let params = seeAll
      ? {
          seeAll,
          ...defaultFilters,
          name: value, // searching for product name only
        }
      : {
          ...defaultFilters,
          page: currentPageNo,
          name: value, // searching for product name only
        };
    setLoading(true);

    services
      .getProducts(params)
      .then((response) => {
        setData(response?.data?.data?.docs);
        setTotalPage(response?.data?.data?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log(`===> :: err`, err);
        setLoading(false);
      });
  };

  const onSearchDebounce = debounce(getFilteredProductData, 1000);
  const getOnSearch = (value) => {
    onSearchDebounce(value);
    setSearchVal(value);
  };

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  return (
    <>
      <div className="content_wrapper all_products_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="box_main">
                <div className="header_top">
                  <h3>Google Ads: Sub-Category</h3>
                </div>
                <div className="middle_main">
                  <div className="row">
                    <SearchAndButtons
                      onSearchChange={getOnSearch}
                      searchPlaceholder={'Search by product name...'}
                      seeAllHandle={seeAllHandle}
                    />
                    <div className="col-md-12 mb-0 mt-4">
                      {loading ? (
                        <Loader loading={loading} />
                      ) : (
                        <ReactTable
                          tableColumns={columns}
                          tableData={data}
                          seeAll={seeAll}
                          nextHandle={nextHandle}
                          prevHandle={prevHandle}
                          totalPage={totalPage}
                          pageNo={currentPageNo}
                          loading={loading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogeAdsCatsTable;
