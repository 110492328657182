import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
const OrderDetails = (props) => {
  const { orderData } = props;
  const { variantId, id } = useParams();
  const [thisOrderItem, setThisOrderItem] = useState();

  useEffect(() => {
    if (orderData) {
      let thisProductData = orderData?.orderItem.find(
        (el) => el.variant._id === variantId,
      );
      setThisOrderItem(thisProductData);
    }
  }, [orderData]);
  return (
    <div className="box_main">
      <div className="header_top ">
        <h3>Order Details</h3>
      </div>
      <div className="middle_main">
        <div className="row ">
          <div className="col-12">
            <table
              className="table condensed_table"
              style={{
                marginBottom: '0rem',
              }}
            >
              <tbody className="order_details">
                <tr>
                  <td width="30%">
                    <p className="customer_title_1">Order ID:</p>
                  </td>
                  <td width="70%">
                    <p className="customer_title_2">
                      {orderData?.order_number}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Delivery Date:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {moment(orderData?.delivery?.delivery_on).format(
                        'MM-DD-yyyy',
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Qty:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {thisOrderItem?.quantity}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Brand:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {thisOrderItem?.product?.brand?.name}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Strength:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {thisOrderItem?.variant?.strength}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Package:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {thisOrderItem?.variant?.size}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Product:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {thisOrderItem?.product?.name}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Variant:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {thisOrderItem?.variant?.attributes ? (
                        JSON.stringify(
                          thisOrderItem?.variant?.attributes,
                        ).substring(
                          1,
                          JSON.stringify(thisOrderItem?.variant?.attributes)
                            .length - 1,
                        )
                      ) : (
                        <>-</>
                      )}
                    </p>
                  </td>
                </tr>
                {thisOrderItem?.doctor ? (
                  <tr>
                    <td>
                      <p className="customer_title_1">Approved By:</p>
                    </td>
                    <td>
                      <p className="customer_title_2">
                        {thisOrderItem?.doctor?.first_name}
                      </p>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
